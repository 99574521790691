@font-face {
  font-family: "Henrik";
  src: local(Henrik-Regular), url("./fonts/Henrik-Regular.woff") format("woff");
}

.App {
  text-align: center;
}

.App-header {
  /* background-color: #282c34; */
  font-family: "Henrik";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 11vw;
  color: #dae3f6;
}
